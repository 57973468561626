<template>
  <div>
    <v-form>
      <p class="mb-5">Match each of the terms below to their definitions.</p>

      <v-simple-table>
        <tr v-for="question in questions" :key="question.letter">
          <td><stemble-latex :content="question.text" /></td>
          <td>
            <v-select
              :key="options.value"
              v-model="inputs[question.inputName]"
              class="d-sm-inline-block my-n3"
              :items="optionsShuffled"
              item-text="text"
              item-value="value"
              style="width: 357px"
            >
              <template #item="{item}">
                <stemble-latex class="no-text-transform" :content="item.text" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.text" />
              </template>
            </v-select>
          </td>
        </tr>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'Chem1LD_Q3S1_Question5',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
      },
      questions: [
        {text: 'a) Acute Toxicity', inputName: 'input1'},
        {text: 'b) Chronic Toxicity', inputName: 'input2'},
        {text: 'c) LC$_\\text{50}$', inputName: 'input3'},
        {text: 'd) LD$_\\text{50}$', inputName: 'input4'},
        {text: 'e) Toxicant', inputName: 'input5'},
        {text: 'f) Toxicity', inputName: 'input6'},
        {text: 'g) Toxicology', inputName: 'input7'},
        {text: 'h) Toxin', inputName: 'input8'},
      ],
      options: [
        {text: 'study of adverse effects of chemicals', value: 'studyAdverse'},
        {text: 'ability of a chemical to damage an organ', value: 'damageOrgan'},
        {text: 'chemical producing toxic effects', value: 'toxicEffects'},
        {text: 'toxic substance made by plant, animal, fungi or bacterium', value: 'toxicByPAFB'},
        {text: 'ability of a chemical to do damage with a single dose', value: 'damageOneDose'},
        {text: 'ability of a chemical to do damage after multiple doses', value: 'damageManyDose'},
        {text: 'lethal dose that kills 50% of a population', value: 'lethalDose50pop'},
        {text: 'lethal concentration that kills 50% of a population', value: 'lethalConc50pop'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
